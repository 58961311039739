import { useContext, useEffect, useRef, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Components/ui
import { SearchResult } from './components';
import {
  InputAdornment,
  TextField,
  CircularProgress,
  IconButton,
  Popper,
  Fade,
  Paper,
} from '@mui/material';
// Icons
import { Clear, Search } from '@mui/icons-material';
// Context
import { HeaderContext } from '../context/HeaderProvider';
// Slice
import { selectContactsStatus } from 'redux/features/contactsSlice/contactsSlice';
import { DataContext } from 'context/contextApi';

const SearchInput = () => {
  const { t } = useTranslation();

  const searchInputRef = useRef<HTMLInputElement>(null);
  const popperRef = useRef(null); // Referencia para el Popper

  const {
    searchContacts,
    contactSearchField,
    setContactSearchField,
    clearSearchField,
    anchorEl,
    setAnchorEl,
    handleClose,
  } = useContext(HeaderContext);
  const { isMobile } = useContext(DataContext);

  const [timer, setTimer] = useState<NodeJS.Timeout>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const contactsStatus = useSelector(selectContactsStatus);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    event.preventDefault();
    if (!setAnchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const { value } = event.target;
    clearTimeout(timer);
    if (value) {
      setContactSearchField(value);
      setTimer(setTimeout(() => searchContacts(value), 1000));
    } else {
      setIsLoading(false);
      clearSearchField();
    }
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    }
    return () => clearTimeout(timer);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      if (contactSearchField === '') {
        setAnchorEl(null);
      } else clearSearchField();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        anchorEl &&
        !anchorEl.contains(event.target)
      ) {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, anchorEl, handleClose]);

  useEffect(() => {
    setIsLoading(contactsStatus === 'loading');
  }, [contactsStatus]);

  useEffect(() => {
    const handleFocusShortcut = (event: KeyboardEvent) => {
      if (
        (event.key === 'f' || event.key === 'F') &&
        document.activeElement !== searchInputRef.current &&
        document.activeElement?.tagName !== 'INPUT' &&
        document.activeElement?.tagName !== 'TEXTAREA' &&
        document.activeElement?.getAttribute('contenteditable') !== 'true'
      ) {
        event.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleFocusShortcut);

    return () => {
      document.removeEventListener('keydown', handleFocusShortcut);
    };
  }, []);

  return (
    <>
      <TextField
        inputRef={searchInputRef}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {isLoading ? (
                <CircularProgress size={18} />
              ) : (
                <Search
                  sx={{
                    fontSize: '18px',
                    color: (theme) => theme.palette.text.primary,
                  }}
                />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {contactSearchField && (
                <IconButton
                  onClick={clearSearchField}
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  <Clear
                    sx={{
                      fontSize: '18px',
                    }}
                  />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        variant="outlined"
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': {
            top: 0,
            borderColor: (theme) => theme.palette.text.primary,
            opacity: 0.5,
          },
          '& .MuiInputBase-input': {
            height: '0.8375em',
            color: (theme) => theme.palette.text.primary,
          },

          width: isMobile ? '90vw' : '50vw',
          marginLeft: isMobile ? '-90px' : '0px',
        }}
        size="small"
        placeholder={t('inputSearch.searchBy')}
        onFocus={handleClick}
        onKeyDown={handleKeyDown}
        value={contactSearchField}
        onChange={handleChange}
      />
      <Popper
        ref={popperRef}
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-40, 0], // Ajusta el desplazamiento en el eje horizontal (-40) y vertical (0)
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ zIndex: 1 }}>
              <SearchResult />
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default SearchInput;
